@tailwind base;
@tailwind components;
@tailwind utilities;







