/* Hide the up and down arrow indicators */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


.border-none {
    border: none !important;
}