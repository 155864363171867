.container {
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
}

.main-content {
    background: linear-gradient(to bottom, rgba(255, 0, 0, 0.1), rgba(0, 0, 255, 0.1));
    width: 100%;
    height: 100vh;
    display: flex;
}

.left-side h1 {
    font-size: 70px;
    margin-left: 50px;
    margin-top: 100px;
    font-weight: bold;
    line-height: 1.1;
}

.left-side p {
    margin-left: 50px;
    margin-top: 10px;
}

.right-side {
    width: 510px;
    height: 477px;
    background-color: #fff;
    border-radius: 10px;
    margin-top: 100px;
    margin-left: 200px;
    box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.3);
}

.right-side h2 {
    background-color: #00008b;
    padding: 15px;
    border-radius: 10px 10px 0px 0px;
    color: white;
    font-weight: 600;
}

.right-side label {
    color: #ffa500;
}

.right-side input {
    border: 1px solid black;
    width: 230px;
    margin-left: 15px;
    height: 40px;
    margin-top: 16px;
    padding-left: 10px;
    font-size: 15px;
}

.package-details h3 {
    padding: 15px;
    color: black;
    font-weight: 600;
    margin-top: 30px;
}

 .dropdown {
    position: relative;
    width: 100%;
    cursor: pointer;
}

.dropdown .dropdown-input {
    position: relative;
    cursor: pointer;
}

.dropdown .dropdown-input input {
    width: calc(100% - 30px);
    cursor: pointer;
}

.dropdown .dropdown-icon {
    position: absolute;
    top: 60%;
    right: 35px;
    transform: translateY(-50%);
    cursor: pointer;
}
.dropdown-menu {
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    z-index: 1000;
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: calc(100% - 40px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    margin-left: 20px;
}


.dropdown:hover .dropdown-menu {
    display: block;
    margin-left: 20px;
}

.dropdown-menu li {
    padding: 10px;
    cursor: pointer;
}

.dropdown-menu li:hover {
    background-color: #00008b;
    color: white;
    font-weight: 600;
}

.right-side button {
    width: 40%;
    height: 40px;
    color: white;
    background-color: #00008b;
    border: 2px solid #d9d9d9;
    margin: 20px auto; 
    padding-left: 10px;
    border-radius: 5px;
    font-weight: 600;
    margin-left: 150px;
    margin-top: 40px;
}

.left-side span {
    background: linear-gradient(to right, #0500ff, #ff0000); 
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-weight: 500;
}

#second-image {
    width: 600px;
    height: 360px;
}
@media only screen and (max-width: 600px) {
    .container,
    .main-content {
        flex-direction: column; 
        align-items: center;
        height: 100vh; 
    }

    .left-side,
    .right-side {
        width: 90%; 
        margin: 20px auto; 
    }

    .right-side {
        order: 1;
    }

    .left-side h1 {
        font-size: 40px;
        margin-left: 20px; 
        margin-top: 50px; 
    }

    .left-side p {
        margin-left: 20px;
        margin-top: 10px;
    }

    .right-side h2 {
        padding: 10px; 
    }

    .right-side label,
    .right-side input {
        width: 100%; 
        margin: 10px auto; 
    }

    .package-details h3 {
        margin-top: 20px;
    }

    .dropdown {
        width: 100%; 
    }
    .right-side input{
        width: 270px;
        margin-left: 20px;
    }

    .dropdown .dropdown-icon {
        right: 40px; 
        margin-top: -5px;
    }

    .right-side button {
        width: 80%; 
        margin-top: 20px; 
        margin-left: auto; 
        margin-right: auto; 
    }

    .left-side span {
        font-size: 24px; 
    }

    #second-image {
        width: 100%;
        height: auto; 
    }
    .dropdown-menu {
            width: calc(100% - 20px);
            left: -10px;
        }
}


